html,
body,
#root {
  margin: 0;
  padding: 0;
  background: #f8f9fd;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

a:focus,
input:focus,
p:focus,
div:focus {
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

.fileContainer {
  box-shadow: none;
}

.uploadPictureContainer {
  width: 90% !important;
  padding: 0 !important;
  margin: 0 !important;
}

.fileContainer .deleteImage {
  font-size: 18px;
}
